import "survey-core/defaultV2.min.css";

import {Dispatch, SetStateAction, useCallback} from "react";

import {Model} from "survey-core";
import {ContrastLightPanelless} from "survey-core/themes";
import {Survey} from "survey-react-ui";

const genQuestion = (question: string, name: string) => ({

    "type": "rating",
    "name": name,
    "title": question,
    "autoGenerate": false,
    "isRequired": true,
    "rateValues": [
        1,
        2,
        3,
        4,
        5
    ],
    "rateMax": 5
})

// 1. Frequent delays in task completion due to waiting on email responses.
// LUM-55 auto-reminder feature
// 2. Collaboration between departments is hindered by miscommunications and misunderstandings via lenghty emails. Language barrier is often major challenge.
// basic: clear process chain and required input forms incl. LUM-50 adaptive routing for execution LUM-3 content translations
// 3. Difficulty tracking the progress of requests and task accountability. We do not have effective tools for tracking and measuring process performance.
// basic: LUM-10 clear assignment matrix and overview of request task assignments
// 4. Lack of real-time visibility into process bottlenecks and cycle times. Our current business process management tools do not provide real-time data analytics.
// LUM-2 analytics page incl. critical path
// 5. We struggle to maintain compliance and audit trails with our existing process management tools.
// LUM-6 LUM-5 automatic process descriptions incl. LUM-56 audit logs
// 6. Our current systems do not provide a user-friendly interface for process management.
// basic: lumen editor is designed for ease of use and simplicity
// 7. We face challenges in automating repetitive tasks within our current processes.
// LUM-58 custom job imports, pre-defined job execution at the right time
// 8. Changes in our processes require a lot of IT resources and time. Adapting our business processes to new market demands is time-consuming and difficult.
// basic: the lumen editor is designed for business users with little to know IT knowledge and they are empowered to adapt the processes themselves
// 9. Our organization lacks a unified platform for managing and executing business processes. The processes are described in word documents and spreadsheets if at all. Only experts know who to contact for what.
// LUM-6 LUM-5 automatic process descriptions
// 10. mhm.
//
//
//
//
//
// ---
// link features and recommend the user the best features of lumen out of the poor results
const surveyJson = {
    completedHtml: "Calculating... brrr!",
    pages: [
        // {
        //     elements: [
        //         {
        //             type: "html",
        //             html: "In this survey, we will ask you a couple questions about your own processes and organization.",
        //         },
        //     ],
        // },
        {
            elements: [
                genQuestion(
                    "Frequent delays in task completion due to waiting on email or chat responses.",
                    "autoreminder",
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Collaboration between departments is hindered by miscommunications and misunderstandings via lenghty emails. Language barrier is often major challenge.",
                    "miscommunication"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Difficulty tracking the progress of requests and task accountability. We do not have effective tools for tracking and measuring process performance.",
                    "accountability"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Lack of real-time visibility into process bottlenecks and cycle times. Our current business process management tools do not provide real-time data analytics.",
                    "analytics"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "We struggle to maintain compliance and audit trails with our existing process management tools.",
                    "documentation"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Our current systems do not provide a user-friendly interface for process management.",
                    "ease-of-use"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "We face challenges in automating repetitive tasks within our current processes. ",
                    "automation"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Changes in our processes require a lot of IT resources and time. Adapting our business processes to new market demands is time-consuming and difficult.",
                    "savings"
                ),
            ],
        },
        {
            elements: [
                genQuestion(
                    "Our organization lacks a unified platform for managing and executing business processes. The processes are described in word documents and spreadsheets if at all. Only experts know who to contact for what.",
                    "experts"
                ),
            ],
        },
    ],
};

// Extracting the name type dynamically
type QuestionElement = typeof surveyJson.pages[number]["elements"][number];
type SurveyData = {
    [K in QuestionElement as K["name"]]: number;
};

const calculateEfficiencyIndex = (data: SurveyData): [ScoreTable, number] => {
    let total = 0;
    const scoreTable: ScoreTable = {};
    for (const [key, value] of Object.entries(data)) {
        // transform to 0-4 scale
        const val = Math.abs(value - 5)
        const partialScore = (val/4) * 1000;
        total += partialScore;

        if (key === "autoreminder") {
        } else if (key === "miscommunication") {
        } else if (key === "accountability") {
        } else if (key === "analytics") {
        } else if (key === "documentation") {
        } else if (key === "ease-of-use") {
        } else if (key === "automation") {
        } else if (key === "savings") {
        } else if (key === "experts") {
        } else if (key === "mhm") {
        }
        scoreTable[key] = {
            inputValue: value,
            transformedValue:val,
            partialScore
        }
    }

    return [scoreTable, total];
};

export type ScoreTable = {
    [K in QuestionElement as K["name"]]: {
        inputValue: number;
        transformedValue: number;
        partialScore: number;
    }
}

interface EfficiencyIndexSurveyProps {
    finalScoreCallback: (table: ScoreTable, total: number) => void;
}

export default function EfficiencyIndexSurvey({finalScoreCallback}: EfficiencyIndexSurveyProps) {
    const survey = new Model(surveyJson);
    survey.applyTheme(ContrastLightPanelless);

    const surveyComplete = useCallback((model) => {
        const [table, total] = calculateEfficiencyIndex(model.data);
        finalScoreCallback(table, total);
    }, []);

    survey.onComplete.add(surveyComplete);

    return (
        <Survey model={survey}/>
    );
}
